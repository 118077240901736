body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
    --title-linear-gradient: lightpink, #F539B9, #A430E8, #8D2DF6;
    --default-linear-gradient: lightgreen, #34E6BD, #38BBD4, #39A7DF;
    --project-linear-gradient: #b6fbff, #83a4d4;
    --past-work-linear-gradient: 
    #00d2ff,
    #928dab
}

body {
    color: white;
    background: black;
    font-family: "Space Grotesk", sans-serif;
}

.select {
    height: 40px;
    width: 100%;
    overflow: hidden;
    position: relative;
    border-radius: 3px;
    margin-bottom: 0.5em;
}

.select:after {
    content: "\25BC";
    padding: 12px 8px;
    position: absolute;
    right: 10px;
    top: 0;
    z-index: 1;
    text-align: center;
    width: 10%;
    height: 100%;
    pointer-events: none;
}

.App {
    text-align: center;
    padding-top: 4em;
    padding-bottom: 4em;
}

.App-logo {
    height: 10vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        -webkit-animation: App-logo-spin infinite 20s linear;
                animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: black;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

#title {
    margin-bottom: 0;
}

#title span {
    color: grey;
}

#bio{
    font-size: small;
    color: rgb(180, 180, 180);
    transition: 500ms;
}

.description{
    font-size: small;
    color: rgb(105, 105, 105);
    transition: 500ms;
}

.App-link {
    background: linear-gradient(lightgreen, #34E6BD, #38BBD4, #39A7DF);
    background: linear-gradient(var(--default-linear-gradient));
    -moz-background-clip: text;
    -o-background-clip: text;
    -webkit-background-clip: text;
    background-clip: text;
    -moz-text-fill-color: transparent;
    -o-text-fill-color: transparent;
    -ms-text-fill-color: transparent;
    -webkit-text-fill-color: transparent;
    text-decoration: none;
}

#greet-link:hover {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    z-index: 1;
}

.linkHover:hover {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    z-index: 1;
}

.App-link-title {
    background: linear-gradient(lightpink, #F539B9, #A430E8, #8D2DF6);
    background: linear-gradient(var(--title-linear-gradient));
    -moz-background-clip: text;
    -o-background-clip: text;
    -webkit-background-clip: text;
    background-clip: text;
    -moz-text-fill-color: transparent;
    -o-text-fill-color: transparent;
    -ms-text-fill-color: transparent;
    -webkit-text-fill-color: transparent;
    text-decoration: none;
}

.link-wildcard {
    -moz-text-fill-color: grey;
    -o-text-fill-color: grey;
    -ms-text-fill-color: grey;
    -webkit-text-fill-color: grey;
    color: grey;
}

@-webkit-keyframes App-logo-spin {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

@keyframes App-logo-spin {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

